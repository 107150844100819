<template>
    <van-form @submit="onSubmit">

        <van-cell-group title="原包装" inset>
          <van-cell>
            <van-field
                    v-model="oldCode"
                    name="oldCode"
                    label="箱号"
                    placeholder="请扫描包装二维码"
                    required
                    :rules="[{ required: true, message: '请扫描包装二维码' }]"
                    @focus="this.focusModel = 'oldCode'"></van-field>
          <van-button round block type="primary" @click="this.show= true,lastShow='oldCode'" style="height: 5.33333vw">
            扫描二维码
          </van-button>
          </van-cell>

          <QRReader
              v-model:show="show"
              @onRead="onRead1"
          />

            <van-field
                    v-model="oldQty"
                    name="oldQty"
                    label="当前数量"
                    required
                    :readonly="true"
                    :rules="[{ required: true, message: '请填写数量' }]"
            />
        </van-cell-group>

        <van-cell-group title="翻包包装" inset>
          <van-cell>
              <van-field
                      v-model="rummageCode"
                      name="rummageCode"
                      label="箱号"
                      placeholder="请扫描包装二维码"
                      required
                      :rules="[{ required: true, message: '请扫描包装二维码' }]"
                      @focus="this.focusModel = 'rummageCode'"></van-field>
            <van-button round block type="primary" @click="this.show= true,lastShow='rummageCode'" style="height: 5.33333vw">
              扫描二维码
            </van-button>
          </van-cell>
            <van-field
                    v-model="rummageQty"
                    name="rummageQty"
                    label="容纳数量"
                    required
                    :rules="[{ required: true, message: '请填写数量' }]"
            />
        </van-cell-group>

        <div style="margin: 4.26667vw;">
            <van-button round block type="primary" native-type="submit">确定翻包</van-button>
        </div>
    </van-form>
</template>

<script>
    import Picker from "../../components/Picker";
    import QRReader from "@/components/QRReader.vue";
    import LocationScanWin from "@/views/pickOrder/LocationScanWin.vue";
    import ListCard from "@/components/ListCard.vue";

    export default {
      components: {QRReader},

      name: "Rummage",
        created() {
            this.getBarcode();
        },
        data() {
            return {
              show:false,
              lastShow:"",
              oldCode: '',
              oldQty: '',
              rummageCode: '',
              rummageQty: '',
              focusModel: ''
            };
        },

        methods: {
          onRead1(text) {
            var me=this;
            if(this.lastShow==="oldCode"){
              this.oldCode = text;
              var option = {
                portName: 'fr.warehouse.WareHouseMaterial.getWareHouseMaterialByCode',
                data: {'packageCode': text},
                needLoading: false,
                successCallback: function (data) {
                  if (data.entities.length > 0) {
                    me.oldQty = data.entities[0].qty
                  }
                }
              };
              this.$sapi.callPort(option);
            }else if(this.lastShow==="rummageCode"){
              this.rummageCode = text;
              var option1 = {
                portName: 'fr.warehouse.WareHouseMaterial.getWareHouseMaterialByCode',
                data: {'packageCode': text},
                needLoading: false,
                successCallback: function (data) {
                  if (data.entities.length > 0) {
                    me.rummageQty = data.entities[0].checkQty;
                  }
                }
              };
              this.$sapi.callPort(option1);

            }
          },

          getBarcode() {
                if (!window.Capacitor) {
                    return;
                }
                var me =this;
                Capacitor.Plugins.Scanner.addListener('onScan', (data) => {
                    var barcode = data.barcode;
                    console.log(data.barcode);
                    let focusModel = this.focusModel;
                    switch (focusModel) {
                        case  'oldCode':
                            this.oldCode = barcode;
                            var option = {
                                portName: 'fr.warehouse.WareHouseMaterial.getWareHouseMaterialByCode',
                                data: {'packageCode': barcode},
                                needLoading: false,
                                successCallback: function (data) {
                                    if (data.entities.length > 0) {
                                        me.oldQty = data.entities[0].qty
                                    }
                                }
                            };
                            this.$sapi.callPort(option);
                            break;
                        case  'rummageCode':
                            this.rummageCode = barcode;
                            var option1 = {
                                portName: 'fr.warehouse.WareHouseMaterial.getWareHouseMaterialByCode',
                                data: {'packageCode': barcode},
                                needLoading: false,
                                successCallback: function (data) {
                                    if (data.entities.length > 0) {
                                        me.rummageQty = data.entities[0].checkQty;
                                    }
                                }
                            };
                            this.$sapi.callPort(option1);
                            break;
                    }
                })

            },
            onSubmit: function (values) {
                var me = this;
                var option = {
                    portName: 'fr.order.StockInDetail.rummage',
                    data: values,
                    needLoading: false,
                    successCallback: function (data) {
                        me.id = data.data.id;
                        me.$dialog.alert({
                            title: '',
                            message: '翻包成功',
                        }).then(() => {
                            me.oldCode = '';
                            me.oldQty = '';
                            me.rummageCode = '';
                            me.rummageQty = '';
                        });
                    }
                };
                this.$sapi.callPort(option);
            },
        }

    }
</script>

<style scoped>

</style>