<template>
    <van-popup v-model:show="this.$props.showWin"
               :style="{ height: '80%' }"
               position="bottom"
               :close-on-click-overlay="false"
               :lazy-render='true'
               teleport="body"
               closeable
               @click-close-icon="closeWin"
    >

        <van-form @submit="onConfirm">
            <van-cell-group title="绑定库内位置" inset>
              <van-cell> <Picker
                  name="pickOrderOutStockId"
                  label="库区"
                  placeholder="请选择库区"
                  displayField="name"
                  valueField="id"
                  :required=false
                  v-model:value="this.$data['wareHouseAreaId']"
                  :defConfig="{
                    portName:'fr.warehouse.WareHouseArea.getOperateWareHouseAreaList'}"
                  :canSearch=true
                  @onConfirm="onWareHouseAreaConfirm"
              />
                <Picker
                    name="pickOrderOutStockId"
                    label="库位"
                    placeholder="请选择库位"
                    displayField="name"
                    valueField="id"
                    :required=false
                    v-model:value="this.$data['wareHouseLocationId']"
                    :defConfig="{
                      portName:'fr.warehouse.WareHouseLocation.getOperateWareHouseLocations',
                      data: {'wareHouseArea.id': this.wareHouseAreaId}}"
                    :canSearch=true
                    @onConfirm="onWareHouseLocationConfirm"
                />
                <van-field
                    v-model="location"
                    name="location"
                    label="库位编码"
                    placeholder="请扫描库位编码"
                    required
                    :rules="[{ required: true, message: '请扫描库位编码' }]"
                    @focus="this.$emit('onfocus', 'location')"
                ></van-field>
                <van-button round block type="primary" @click="this.$emit('onfocus')" style="height: 5.33333vw">
                  扫描二维码
                </van-button>
              </van-cell>

              <div style="margin: 4.26667vw;">
                    <van-button round block type="primary" native-type="submit">
                        确定
                    </van-button>
                </div>
            </van-cell-group>
        </van-form>
    </van-popup>
</template>

<script>
    import _ from "lodash";
    import Picker from "../../components/Picker";

    export default {
        name: "LocationScanWin",
      components: {Picker},
        props: {
            showWin: {
                default: false,
                type: Boolean
            },


        },

        emits: ['update:show', 'onConfirm'],

        data() {
            return {
              lastShow:"",
              location: '',
              wareHouseLocationId: '',
              wareHouseAreaId: ''
            };
        },

        created() {

        },


        methods: {
          onRead(text) {
            var me=this;
          },
            onConfirm(value, index) {
                if (_.isEmpty(value)) {
                    return;
                }
              this.location = '';
              this.wareHouseLocationId = '';
              this.wareHouseAreaId = '';
                this.$emit('update:show', false);
                this.$emit('onConfirm', value);
            },
            closeWin(value, index) {
              this.location = '';
              this.wareHouseLocationId = '';
              this.wareHouseAreaId = '';
              this.$emit('update:show', false);
            },
          onWareHouseAreaConfirm(value) {
            var me = this;
            me.location = value.code;
          },
          onWareHouseLocationConfirm(value) {
            var me = this;
            me.location = value.code;
          }
        }
    }
</script>

<style scoped>

</style>